<script setup lang="ts">
import type { HouseDetailsResponse } from 'lc-services/modules/search/usecases/GetHouseDetails/types'
import type { HouseDetailsAttributes } from 'lc-services/types'
import type { HouseSaleInformations } from '~/types/house/types'

const props = withDefaults(
  defineProps<{
    initialStep?: number
    house: HouseDetailsResponse
    houseDestinations?: HouseDetailsAttributes['destination']
    houseSaleInformations: HouseSaleInformations
    privateToken?: string
  }>(),
  {
    initialStep: 0,
    houseDestinations: () => ({}) as HouseDetailsAttributes['destination'],
    privateToken: '',
  },
)

const { user } = useUser()

const currentStep = ref(props.initialStep)

const setStep = (value: number) => {
  currentStep.value = value
}
</script>

<template>
  <div>
    <LazyHouseTransactionInquirySummary
      v-if="currentStep === 0"
      class="min-h-[calc(100vh-3rem)] w-full md:min-h-max md:p-7"
      :house="house"
      :house-destinations="houseDestinations"
      :house-sale-informations="houseSaleInformations"
      @step="setStep"
    />
    <LazyHouseTransactionInquiryForm
      v-if="currentStep === 1"
      class="min-h-[calc(100vh-3rem)] w-full md:min-h-max md:p-7"
      :house="house"
      :house-destinations="houseDestinations"
      :private-token="privateToken"
      :user-infos="user"
      @step="setStep"
    />
    <LazyHouseTransactionInquirySuccess
      v-if="currentStep === 2"
      class="min-h-[calc(100vh-3rem)] w-full md:min-h-max md:p-7"
    />
  </div>
</template>
